<template>
  <div class="mooc_test">
    <div class="unsolved" v-if="testInfo.ref && !testInfo.ref.submit">
      <div class="topic">
        {{ testInfo.ref.subject }}
        ({{ testInfo.typeName }}，{{ testInfo.ref.score }}分)
      </div>
      <div class="options" v-if="info.ref.type === 1">
        <el-radio-group
          v-model="submitForm.answer"
          v-for="(item, index) in testInfo.ref.options"
          :key="index"
          class="my_options"
        >
          <el-radio :label="item.name">
            {{ item.name }}.{{ item.value }}
          </el-radio>
        </el-radio-group>
      </div>
      <div class="options" v-if="info.ref.type === 2">
        <el-checkbox-group
          v-model="submitForm.checkAnswer"
          v-for="(item, index) in testInfo.ref.options"
          :key="index"
          class="my_options"
        >
          <el-checkbox :label="item.name">
            {{ item.name }}.{{ item.value }}
          </el-checkbox>
        </el-checkbox-group>
      </div>
      <div class="options" v-if="info.ref.type === 3">
        <div class="op_radio_con">
          <div
            v-for="(item, index) in testInfo.ref.options"
            :key="index"
            @click="submitForm.answer = item.name"
            class="op_radio"
            :class="submitForm.answer === item.name ? 'check' : ''"
          >
            {{ item.value }}
          </div>
        </div>
      </div>
      <div class="options" v-if="info.ref.type === 4">
        <span class="options-label"> 作答： </span>
        <MyEditor @onEditor="onEditor" />
      </div>
      <div class="submit">
        <el-button type="primary" @click="handleSubmit">提交</el-button>
      </div>
    </div>
    <div class="solved" v-else>
      <div class="topic">
        {{ testInfo.ref.subject }}
        ({{ testInfo.typeName }}，{{ testInfo.ref.score }}分)
      </div>
      <div v-if="info.ref.type !== 4">
        <div
          class="right_answer"
          v-if="info.ref.type === 1 || info.ref.type === 2"
        >
          <div
            v-for="(item, index) in testInfo.ref.options"
            :key="index"
            class="right_answer_item"
          >
            {{ item.name }}.
            <span
              v-if="
                testInfo.ref.answer.indexOf(String.fromCharCode(65 + index)) !==
                -1
              "
              class="right_bar"
            >
              正确
            </span>
            {{ item.value }}
          </div>
        </div>
        <div class="right_answer" v-if="info.ref.type === 3">
          <div class="op_radio_con">
            <div
              v-for="(item, index) in testInfo.ref.options"
              :key="index"
              @click="submitForm.answer = item.name"
              class="op_radio"
              :class="
                testInfo.ref.answer.indexOf(String.fromCharCode(65 + index)) !==
                -1
                  ? 'right'
                  : ''
              "
            >
              {{ item.value }}
            </div>
          </div>
        </div>
        <div class="my_answer">
          我的作答：
          <span
            :class="
              testInfo.ref.submit === testInfo.ref.answer ? 'right' : 'wrong'
            "
          >
            {{ testInfo.ref.submit }}
          </span>
        </div>
        <div class="score">
          <span>得分：{{ testInfo.ref.submitScore }}分</span>
          <span>
            平均分：
            {{ testInfo.average }}
            分
          </span>
          <span>
            正确率：
            {{ testInfo.correctRate }}
            %
          </span>
        </div>
        <div class="knowledge">
          <div class="knowledge_tag">知识点</div>
          <div class="knowledge_item">{{ testInfo.kpointName }}</div>
        </div>
        <div class="analies">
          <div class="analies_tag">解析</div>
          <div class="analies_content">
            {{ testInfo.ref.parse }}
          </div>
        </div>
      </div>
      <!-- 这里要判断问答题老师是否评价 -->
      <div v-if="info.ref.type === 4" class="no-comment">
        <div>
          <i class="el-icon-success"></i>
        </div>
        已提交，等待教师评分
      </div>
    </div>
  </div>
</template>

<script>
import courseApi from "@/api/course";
import MyEditor from "@/components/MyEditor/index.vue";
export default {
  name: "MoocTest",
  props: {
    info: Object,
  },
  components: { MyEditor },
  data() {
    return {
      testInfo: {
        ref: {},
        typeName: "",
      },
      submitForm: {
        answer: "",
        checkAnswer: [],
      },
      rightAnswer: "B",
    };
  },
  mounted() {
    this.getDetail();
  },
  methods: {
    onEditor(value) {
      this.submitForm.answer = value;
    },
    async getDetail() {
      const res = await courseApi.ViewJob({
        actionId: this.info.actionId,
        actionType: this.info.actionType,
      });
      this.testInfo = res.data;
      switch (this.info.ref.type) {
        case 1: {
          this.testInfo.typeName = "单选题";
          break;
        }
        case 2: {
          this.testInfo.typeName = "多选题";
          break;
        }
        case 3: {
          this.testInfo.typeName = "判断题";
          break;
        }
        case 4: {
          this.testInfo.typeName = "问答题";
          break;
        }
      }
      this.testInfo.kpointName = this.info.ref.kpoints
        .map((item) => {
          return item.name;
        })
        .join("、");
      this.testInfo.average = (
        this.testInfo.ref.correct /
        (this.testInfo.ref.total / this.testInfo.ref.score)
      ).toFixed(2);
      this.testInfo.correctRate = (
        (this.testInfo.ref.correct / this.testInfo.ref.total) *
        100
      ).toFixed(2);
    },

    // 提交
    async handleSubmit() {
      // 这里需要提交接口
      const obj = {
        actionId: this.info.actionId,
        ref: {
          submitScore: this.info.ref.score,
        },
      };
      switch (this.info.ref.type) {
        case 1:
        case 3:
        case 4: {
          obj.ref.submit = this.submitForm.answer;
          break;
        }
        case 2: {
          let arr = this.submitForm.checkAnswer
            .map((item) => {
              return item.charCodeAt();
            })
            .sort((item1, item2) => {
              return item1 - item2;
            })
            .map((item) => {
              return String.fromCharCode(item);
            });
          obj.ref.submit = arr.join(",");
          break;
        }
      }
      if (obj.ref && obj.ref.submit) {
        const res = await courseApi.SubmitJob(obj);
        if (res && res.code === 200) {
          this.$message({
            type: "success",
            message: "提交成功！",
          });
          this.$router.go(0);
        }
      } else {
        this.$message.warning("请选择答案");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.mooc_test {
  position: relative;
  width: calc(100% - 30px);
  height: calc(100% - 40px);
  color: #fff;
  padding: 0px 0 0 30px;
  .unsolved {
    width: 100%;
    height: 100%;
    padding-top: 30px;
    overflow-y: auto;
    .topic {
      font-size: 24px;
      line-height: 40px;
      margin-bottom: 10px;
    }
    .options {
      width: 100%;
      min-height: calc(100% - 40px - 30px - 50px);
      &-label {
        font-size: 24px;
      }
    }
    .submit {
      padding-bottom: 30px;
    }
  }
  .solved {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    padding-top: 30px;
    .topic {
      font-size: 24px;
      line-height: 40px;
      margin-bottom: 10px;
    }
    .right_answer {
      margin-bottom: 55px;
      .right_answer_item {
        display: flex;
        // align-items: center;
        align-items: flex-start;
        color: #fff;
        font-size: 24px;
        line-height: 40px;
        margin-bottom: 10px;
        .right_bar {
          display: inline-block;
          width: 48px;
          height: 24px;
          line-height: 24px;
          text-align: center;
          color: #fff;
          font-size: 14px;
          border-radius: 4px;
          background-color: #11c98a;
          margin: 8px 5px 0 3px;
        }
      }
    }
    .my_answer {
      font-size: 24px;
      margin-bottom: 20px;
    }
    .score {
      font-size: 24px;
      margin-bottom: 40px;
      span {
        margin-right: 20px;
      }
    }
    .knowledge {
      display: flex;
      align-items: center;
      margin-bottom: 40px;
      .knowledge_tag {
        width: 60px;
        height: 24px;
        line-height: 24px;
        text-align: center;
        background-color: #ff8a00;
        border-radius: 4px;
        font-size: 14px;
        margin-right: 20px;
      }
      .knowledge_item {
        font-size: 24px;
      }
    }
    .analies {
      display: flex;
      margin-bottom: 40px;
      .analies_tag {
        width: 60px;
        height: 24px;
        line-height: 24px;
        text-align: center;
        background-color: #b655c2;
        border-radius: 4px;
        font-size: 14px;
        margin-right: 20px;
        margin-top: 10px;
      }
      .analies_content {
        width: calc(100% - 80px);
        font-size: 24px;
        line-height: 40px;
      }
    }
  }
  .op_radio_con {
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding-left: 40px;
    .op_radio {
      width: 92px;
      height: 40px;
      border: 2px solid #888888;
      border-radius: 4px;
      user-select: none;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 18px;
      cursor: pointer;
      transition: all cubic-bezier(0.18, 0.37, 0.48, 0.95) 0.3s;
      &:hover {
        border-color: #ff8a00 !important;
      }
    }
    .check {
      border-color: #ff8a00 !important;
      color: #ff8a00;
    }
    .right {
      border-color: #11c98a !important;
      color: #11c98a;
    }
  }
}
</style>

<style lang="scss">
.my_options {
  display: flex;
  flex-direction: column;

  .el-radio {
    display: flex;
    align-items: center;
    color: #ffffff;
    margin-bottom: 36px;
    .el-radio__input {
      .el-radio__inner {
        width: 24px;
        height: 24px;
        background-color: rgba($color: #000000, $alpha: 0);
        border: 2px solid #ffffff;
      }
    }
    .is-checked {
      .el-radio__inner {
        width: 24px;
        height: 24px;
        background-color: rgba($color: #000000, $alpha: 0);
        border: 2px solid #ff8a00;
      }
      .el-radio__inner::after {
        width: 10px;
        height: 10px;
        background-color: #ff8a00;
      }
    }
    .el-radio__label {
      font-size: 24px;
      line-height: 40px;
      white-space: pre-wrap;
    }
  }
  .el-checkbox {
    display: flex;
    align-items: center;
    color: #ffffff;
    margin-bottom: 36px;
    .el-checkbox__input {
      .el-checkbox__inner {
        width: 23px;
        height: 23px;
        background-color: rgba($color: #000000, $alpha: 0);
        border: 2px solid #ffffff;
      }
    }
    .is-checked {
      .el-checkbox__inner {
        width: 23px;
        height: 23px;
        background-color: rgba($color: #000000, $alpha: 0);
        border: 2px solid #ff8a00;
      }
      .el-checkbox__inner::after {
        width: 13px;
        height: 13px;
        background-color: #ff8a00;
        color: transparent;
        transform: rotate(0) translate(-1px, 2px);
        border: none;
      }
    }
    .el-checkbox__label {
      font-size: 24px;
    }
  }
}
.wangeditor {
  width: calc(100% - 20px);
  height: 400px;
  margin-top: 30px;
}
.right {
  color: #11c98a;
}
.wrong {
  color: #eb2a1c;
}
.no-comment {
  width: 100%;
  height: calc(100% - 150px) !important;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 25px;
  i {
    font-size: 50px;
    color: #67c23a;
    margin-bottom: 20px;
  }
}
</style>