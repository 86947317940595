<template>
  <div class="mooc_work">
    <div class="no_submit" v-if="!workInfo.ref.submit">
      <div class="topic">{{ workInfo.ref.title }}</div>
      <div class="imgs">
        <el-image
          v-for="(item, index) in workInfo.imgs"
          :key="index"
          :src="item"
          :preview-src-list="workInfo.imgs"
          style="width: 240px; height: 135px"
        >
        </el-image>
      </div>
      <div class="my_answer_textarea" v-if="workInfo.ref.submitType === 1">
        <div class="my_answer_key">作答:</div>
        <div class="my_answer_content">
          <MyEditor
            ref="Editor"
            :value="submitForm.answer"
            @onEditor="onEditor"
          ></MyEditor>
        </div>
      </div>
      <div class="my_answer_upload" v-if="workInfo.ref.submitType === 2">
        <div class="my_answer_key">作答:</div>
        <div class="my_answer_content">
          <div class="tips">完成作业后，请点此上传文件！</div>
          <div class="my_upload">
            <el-upload
              class="upload-demo"
              action="#"
              :auto-upload="false"
              :on-change="handleChange"
              :on-remove="handleRemove"
              :file-list="fileList"
            >
              <el-button type="info" size="mini">选择文件</el-button>
              <template #tip>
                <div class="el-upload__tip">未选择任何文件</div>
              </template>
            </el-upload>
          </div>
        </div>
      </div>
    </div>
    <div class="submited" v-if="workInfo.ref.submit && workInfo.ref.comment">
      <div class="topic">{{ workInfo.ref.title }}</div>
      <div class="imgs">
        <el-image
          style="width: 240px; height: 135px"
          :src="item"
          :preview-src-list="workInfo.imgs"
          v-for="(item, index) in workInfo.imgs"
          :key="index"
        >
        </el-image>
      </div>
      <div class="my_answer">
        <div class="my_answer_key">作答:</div>
        <br />
        <div class="my_answer_content">
          <div v-if="workInfo.ref.submitType === 1">
            <div v-html="workInfo.ref.submit"></div>
          </div>
          <div v-if="workInfo.ref.submitType === 2">
            <el-button @click="handleView" type="primary" size="mini">
              点此查看我的作答
            </el-button>
          </div>
        </div>
      </div>
      <div class="grade">
        <div class="grade_key">评分:</div>
        <div class="grade_content">10分</div>
      </div>
      <div class="comment">
        <div class="comment_key">评语:</div>
        <div class="comment_content" v-if="workInfo.ref.comment">
          <div v-html="workInfo.ref.comment"></div>
        </div>
        <div v-else class="wait-comment">
          <div>等待老师评价</div>
        </div>
      </div>
    </div>
    <div class="no-comment" v-if="workInfo.ref.submit && !workInfo.ref.comment">
      <div>
        <i class="el-icon-success"></i>
      </div>
      已提交，等待教师评分
    </div>
    <div class="submit" v-if="!workInfo.ref.submit">
      <el-button type="primary" @click="handleSubmit">提交</el-button>
    </div>
  </div>
</template>

<script>
import MyEditor from "@/components/MyEditor/index.vue";
import courseApi from "@/api/course";
import commonApi from "@/api/common";
export default {
  props: {
    info: Object,
  },
  components: {
    MyEditor,
  },
  data() {
    return {
      // 作业图片
      fileList: [],
      // 选中的文件
      chooseFile: {},
      // 作业信息
      workInfo: {
        imgs: [],
        ref: {},
      },
      // 提交表单
      submitForm: {
        ref: {},
      },
    };
  },
  mounted() {
    this.getDetail();
  },
  methods: {
    // 获取详情
    async getDetail() {
      const res = await courseApi.ViewJob({
        actionId: this.info.actionId,
        actionType: this.info.actionType,
      });
      if (res && res.data) {
        this.workInfo = res.data;
        const imgs = this.info.ref.intro.split("FileTagUniqueSign:");
        if (imgs && imgs.length !== 1 && imgs[1] !== "") {
          this.workInfo.imgs = imgs[1].split("ImgDivisionTagUnique");
        }
        this.submitForm.actionId = this.info.actionId;
      }
    },
    // 更改上传
    handleChange(file) {
      this.fileList = [];
      this.fileList.push(file);
      this.chooseFile = file.raw;
    },
    // 删除相应文件
    handleRemove() {
      this.chooseFile = {};
    },
    onEditor(value) {
      this.submitForm.ref.submit = value;
    },
    // 查看文件类型
    handleView() {
      window.open(this.workInfo.ref.submit);
    },
    // 提交方法
    async handleSubmit() {
      if (this.workInfo.ref.submitType === 1) {
        const result = await courseApi.SubmitJob(this.submitForm);
        if (result && result.code === 200) {
          this.$message({
            type: "success",
            message: "提交成功！",
          });
          this.$router.go(0);
        }
      } else {
        if (this.chooseFile && this.chooseFile.uid) {
          const fm = new window.FormData();
          fm.append("docFile", this.chooseFile);
          const res = await commonApi.Doc(fm);
          if (res && res.code === 200) {
            this.submitForm.ref.submit = res.data.fileUrl;
            const result = await courseApi.SubmitJob(this.submitForm);
            if (result && result.code === 200) {
              this.$message({
                type: "success",
                message: "提交成功！",
              });
              this.$router.go(0);
            }
          }
        } else {
          this.$message.warning("请选择文件");
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.mooc_work {
  position: relative;
  width: calc(100% - 120px);
  height: calc(100% - 120px);
  color: #fff;
  padding: 60px;
  overflow-y: auto;
  .no_submit {
    width: 100%;
    height: calc(100% - 60px);
    overflow-y: auto;
    .topic {
      font-size: 24px;
      margin-bottom: 30px;
      line-height: 40px;
    }
    .imgs {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 30px;
      margin-bottom: 40px;
    }
    .my_answer_textarea {
      display: flex;
      height: 200px;
      .my_answer_key {
        width: 60px;
        margin-right: 20px;
        color: #fff;
        font-size: 24px;
      }
      .my_answer_content {
        width: calc(100% - 80px);
        z-index: 10;
      }
    }
    .my_answer_upload {
      display: flex;
      .my_answer_key {
        width: 60px;
        margin-right: 20px;
        color: #fff;
        font-size: 24px;
      }
      .my_answer_content {
        width: calc(100% - 80px);
        .tips {
          font-size: 24px;
          margin-bottom: 20px;
        }
      }
    }
  }
  .submited {
    width: 100%;
    height: calc(100% - 60px);
    overflow-y: auto;
    .topic {
      font-size: 24px;
      margin-bottom: 30px;
      line-height: 40px;
    }
    .imgs {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 30px;
      margin-bottom: 40px;
    }
    .my_answer {
      padding-bottom: 30px;
      .my_answer_key {
        width: 60px;
        margin-right: 15px;
        font-size: 24px;
        line-height: 40px;
      }
      .my_answer_content {
        width: calc(100% - 75px);
        .my_answer_content_text {
          font-size: 24px;
          line-height: 40px;
        }
        .my_answer_content_img {
          margin-top: 20px;
          display: grid;
          grid-template-columns: repeat(4, 1fr);
          gap: 30px;
          margin-bottom: 40px;
        }
      }
    }
    .grade {
      display: flex;
      margin-bottom: 35px;
      .grade_key {
        width: 60px;
        margin-right: 15px;
        font-size: 24px;
      }
      .grade_content {
        width: calc(100% - 75px);
        color: #ff8a00;
        font-size: 24px;
      }
    }
    .comment {
      .comment_key {
        width: 60px;
        margin-right: 15px;
        font-size: 24px;
        line-height: 40px;
        padding-bottom: 20px;
      }
      .comment_content {
        width: calc(100% - 75px);
        .comment_content_text {
          font-size: 24px;
          line-height: 40px;
        }
        .comment_content_img {
          margin-top: 20px;
          display: grid;
          grid-template-columns: repeat(4, 1fr);
          gap: 30px;
          margin-bottom: 40px;
        }
      }
    }
  }
  .submit {
    position: absolute;
    bottom: 60px;
    left: 60px;
  }
}
</style>

<style lang="scss">
.my_upload {
  .upload-demo {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .el-upload--text {
      width: 100px;
      .el-button {
        background-image: linear-gradient(to top, #aeaeae, #efefef);
        color: #333333;
        font-size: 16px;
        padding: 0px;
        width: 92px;
        height: 32px;
        line-height: 32px;
        text-align: center;
      }
    }
    .el-upload__tip {
      width: calc(100% - 110px);
      margin-left: 10px;
      color: #fff;
      font-size: 18px;
      margin-top: 0px;
    }
    .el-upload-list {
      .el-upload-list__item {
        display: flex;
        align-items: center;
        .el-upload-list__item-name {
          color: #fff;
          font-size: 18px;
          .el-icon-document {
            color: #fff;
            font-size: 18px;
          }
        }
        .el-icon-close {
          color: white;
          top: 15px;
          margin-top: -3px;
          right: 20px;
        }
      }
      .el-upload-list__item:hover {
        background-color: rgba($color: #000000, $alpha: 0);
        .el-upload-list__item-name {
          color: #fff;
          font-size: 18px;
          .el-icon-document {
            color: #fff;
            font-size: 18px;
          }
        }
      }
    }
  }
}
.wait-comment {
  color: #d7d7d7;
}
.no-comment {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 25px;
  i {
    font-size: 50px;
    color: #67c23a;
    margin-bottom: 20px;
  }
}
</style>