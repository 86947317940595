<template>
  <div class="mooc_video">
    <video :src="url" controls autoplay></video>
  </div>
</template>

<script>
export default {
  name: "MoocVideo",
  props: {
    url: String,
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss">
.mooc_video {
  width: 100%;
  height: 100%;
  background-color: black;
  video {
    width: 100%;
    height: 100%;
  }
}
</style>