<template>
  <div class="mooc_details" :style="toggle ? 'display:flex' : 'display:block'">
    <div :class="toggle ? 'middle' : 'big'" class="mooc_details_main">
      <div class="mooc_details_main_header flex_space">
        <div @click="handleBack" class="back">
          <i class="el-icon el-icon-d-arrow-left"></i>
          返回课程主页
        </div>
        <div @click="expand" class="toggle">
          <i
            :class="toggle ? 'el-icon-d-arrow-right' : 'el-icon-d-arrow-left'"
            class="el-icon"
          ></i>
        </div>
      </div>
      <div class="section">
        <div class="period">
          <div>{{ actionInfo.chapterName }}</div>
          <div>{{ actionInfo.periodName }}</div>
        </div>
        <div class="period_title">{{ actionInfo.ref.title }}</div>
      </div>
      <div class="mooc_details_content">
        <Video
          v-if="actionInfo.actionType === 1"
          :url="actionInfo.ref.videoUrl"
        ></Video>
        <Doc v-if="actionInfo.actionType === 2"></Doc>
        <Live v-if="actionInfo.actionType === 3" :info="actionInfo"></Live>
        <Test v-if="actionInfo.actionType === 4" :info="actionInfo"></Test>
        <Exam v-if="actionInfo.actionType === 5"></Exam>
        <Work v-if="actionInfo.actionType === 6" :info="actionInfo"></Work>
      </div>
      <div class="mooc_details_footer flex_space">
        <div class="mooc_details_footer_l">
          <div
            v-for="item in actionInfo.ref.kpoints"
            :key="item.chapterId"
            class="knowledge_item"
          >
            {{ item.name }}
          </div>
        </div>
        <div class="mooc_details_footer_r">
          <el-button type="primary" size="mini">收藏</el-button>
        </div>
      </div>

      <div class="pre">
        <i class="el-icon el-icon-arrow-up"></i>
      </div>
      <div class="next">
        <i class="el-icon el-icon-arrow-down"></i>
      </div>
    </div>
    <div
      class="mooc_details_right"
      :class="toggle ? 'show_right' : 'hide_right'"
    >
      <div class="course_desc">
        <div class="course_text">
          <div class="course_title">{{ courseInfo.title }}</div>
          <div class="course_teacher">
            教师：{{ courseInfo.lecturerName }}{{ courseInfo.teamName }}
          </div>
        </div>
        <div class="course_img">
          <img :src="courseInfo.cover" alt="" />
        </div>
      </div>
      <div class="mooc_tabs">
        <div
          @click="tabsActive = 0"
          :class="tabsActive === 0 ? 'active' : ''"
          class="tabs_item"
        >
          目录
        </div>
        <div
          @click="tabsActive = 1"
          :class="tabsActive === 1 ? 'active' : ''"
          class="tabs_item"
        >
          讨论
        </div>
        <div
          @click="tabsActive = 2"
          :class="tabsActive === 2 ? 'active' : ''"
          class="tabs_item"
        >
          笔记
        </div>
      </div>
      <div v-if="tabsActive === 0" class="tabs_catalogue">
        <div
          v-for="(chitem, chindex) in contentList"
          :key="chitem.chapterId"
          class="chapter_list"
        >
          <div class="chapter_item flex">
            <div>章节</div>
            <div
              :class="
                actionInfo.chapterId === chitem.chapterId ? 'active-num' : ''
              "
            >
              {{ chindex + 1 }}
            </div>
            <div>{{ chitem.title }}</div>
          </div>
          <div
            v-for="(pitem, pindex) in chitem.children"
            :key="pitem.periodId"
            class="period_list"
          >
            <div class="period_item flex">
              <div>课时</div>
              <div
                :class="
                  actionInfo.periodId === pitem.periodId ? 'active-num' : ''
                "
              >
                {{ pindex + 1 }}
              </div>
              <div>{{ pitem.title }}</div>
            </div>
            <div
              v-for="aitem in pitem.children"
              :key="aitem.actionId"
              @click="handleCkPeriod(aitem)"
              :class="aitem.actionId === actionId ? 'active-item' : ''"
              class="topic_item flex"
            >
              <div></div>
              <div>
                <span>
                  {{ aitem.title }}
                </span>
                {{ aitem.textName }}
                <i class="iconfont" :class="aitem.iconName"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import courseApi from "@/api/course";
import Video from "./components/MoocVideo.vue";
import Doc from "./components/MoocDoc.vue";
import Live from "./components/MoocLive.vue";
import Test from "./components/MoocTest.vue";
import Exam from "./components/MoocExam.vue";
import Work from "./components/MoocWork.vue";
export default {
  components: {
    Video,
    Doc,
    Live,
    Test,
    Exam,
    Work,
  },
  data() {
    return {
      // 定时器
      timer: null,
      // 课程信息
      courseInfo: {},
      // 活动id
      actionId: "",
      // 班级Id
      courseId: "",
      // 活动信息
      actionInfo: {
        ref: { title: "" },
        chapterName: "",
        periodName: "",
      },
      // 目录数组
      contentList: [],
      // 活动数组
      actionList: [],
      // 章节数组
      chapterList: [],
      // 课时数组
      periodList: [],
      tabsActive: 0,
      toggle: true,
    };
  },
  mounted() {
    this.actionId = this.$route.params.actionId;
    this.courseId = this.$route.params.courseId;
    this.getMoocDetail();
  },
  methods: {
    // 课程详情
    async getMoocDetail() {
      const res = await courseApi.GetMoocDetail({ courseId: this.courseId });
      if (res && res.data) {
        let teachers = [];
        if (res.data.teamTeacherList) {
          teachers = res.data.teamTeacherList.map((elem) => {
            return elem.name;
          });
        }
        this.courseInfo = {
          ...res.data,
          teamName: teachers.join("、"),
        };
        this.getActionDetail();
      }
    },
    //活动详情接口
    async getActionDetail() {
      const res = await courseApi.GetActionDetail({ actionId: this.actionId });
      if (res && res.data) {
        this.actionInfo = {
          ...res.data,
          teamName:
            this.courseInfo.lecturerName + this.courseInfo.teamName
              ? "、" + this.courseInfo.teamName
              : "",
        };
        this.getContentList();
      }
    },
    // 获取活动内容
    async getContentList() {
      const res = await courseApi.GetContextList({ courseId: this.courseId });
      if (res && res.data) {
        this.actionList = res.data.actionList;
        this.chapterList = res.data.chapterList;
        this.periodList = res.data.periodList;
        this.actionInfo.chapterName = res.data.chapterList.filter(
          (item) => item.chapterId === this.actionInfo.chapterId
        )[0].title;
        this.actionInfo.periodName = res.data.periodList.filter(
          (item) => item.periodId === this.actionInfo.periodId
        )[0].title;

        this.dealList();
      }
    },
    dealList() {
      const now = new Date().getTime();
      // 这三个列表开始整理
      let cid = -1;
      let pid = -1;
      let list = [];
      this.chapterList.forEach((chpItem) => {
        list.push(chpItem);
        list[++cid].children = [];
        pid = -1;
        this.periodList
          .filter((pItem) => pItem.chapterId === chpItem.chapterId)
          .forEach((pdItem) => {
            list[cid].children.push(pdItem);
            list[cid].children[++pid].children = [];
            this.actionList
              .filter((acItem) => acItem.periodId === pdItem.periodId)
              .forEach(async (acItem) => {
                // 判断图标
                let iconName = "";
                let textName = "";
                switch (acItem.actionType) {
                  case 1: {
                    iconName = "icon-shipintianchong";
                    break;
                  }
                  case 2: {
                    iconName = "icon-wendang";
                    break;
                  }
                  case 3: {
                    iconName = "icon-zhibo1";
                    break;
                  }
                  case 4: {
                    iconName = "icon-zuoye-copy";
                    break;
                  }
                  case 5: {
                    iconName = "icon-ceshitiku";
                    break;
                  }
                  case 6: {
                    iconName = "icon-bianji";
                    break;
                  }
                }

                list[cid].children[pid].children.push({
                  ...acItem,
                  iconName: iconName,
                  textName: textName,
                });
              });
          });
      });
      this.contentList = list;
    },
    // 点击课时跳转
    handleCkPeriod(item) {
      if (this.actionId !== item.actionId) {
        this.$router.push(`/rec/mooc-detail/${this.courseId}/${item.actionId}`);
        this.timer = setTimeout(() => {
          window.location.reload();
        }, 300);
      }
    },
    expand() {
      this.toggle = !this.toggle;
    },
    // 返回课程主页
    handleBack() {
      this.$router.push("/rec/mooc/" + this.courseId);
    },
  },
  beforeUnmount() {
    clearTimeout(this.timer);
  },
};
</script>

<style lang="scss" scoped>
.mooc_details {
  width: 100%;
  height: 100%;
  overflow: hidden;
  transition: all cubic-bezier(0.72, -0.51, 0, 1.5) 0.3s;
  background: black;
  .mooc_details_main {
    position: relative;
    background-color: #171717;
    .mooc_details_main_header {
      width: 100%;
      margin-top: 20px;
      margin-bottom: 5px;
      .back {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 128px;
        height: 32px;
        line-height: 32px;
        background-color: #212121;
        border-radius: 0px 4px 4px 0px;
        color: #999999;
        cursor: pointer;
        i {
          color: #999999;
          font-size: 14px;
        }
      }
      .toggle {
        width: 20px;
        height: 20px;
        line-height: 20px;
        text-align: center;
        background: #212121;
        border-radius: 2px 0px 0px 2px;
        cursor: pointer;
        color: #666666;
        font-size: 14px;
      }
    }
    .section {
      display: flex;
      align-items: center;
      margin-left: 80px;
      margin-bottom: 10px;
      .period {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 32px;
        padding: 0 10px;
        margin-right: 20px;
        background-color: #212121;
        div:nth-child(1) {
          color: #999999;
        }
        div:nth-child(2) {
          color: #ffffff;
          margin-left: 10px;
        }
      }
      .period_title {
        color: #ffffff;
        font-size: 14px;
      }
    }
    .mooc_details_content {
      width: calc(100% - 160px);
      height: calc(100% - 170px);
      background-image: url("../../../assets/images/mooc_bg.png");
      margin-left: 80px;
      margin-bottom: 10px;
    }
    .mooc_details_footer {
      width: calc(100% - 160px);
      margin-left: 80px;
      .mooc_details_footer_l {
        display: flex;
        .knowledge_item {
          height: 24px;
          padding: 0 10px;
          text-align: center;
          line-height: 24px;
          background-color: #333333;
          border-radius: 2px;
          margin-right: 10px;
          color: #ffffff;
          font-size: 12px;
        }
      }
    }

    .pre {
      position: absolute;
      top: 0px;
      left: 145px;
      width: 48px;
      height: 24px;
      line-height: 24px;
      text-align: center;
      background-color: #212121;
      border-radius: 0px 0px 4px 4px;
      color: #656565;
      font-size: 16px;
      cursor: pointer;
    }
    .next {
      position: absolute;
      bottom: 0px;
      left: 145px;
      width: 48px;
      height: 24px;
      line-height: 24px;
      text-align: center;
      background-color: #212121;
      border-radius: 4px 4px 0px 0px;
      color: #656565;
      font-size: 16px;
      cursor: pointer;
    }
  }
  .mooc_details_right {
    width: 320px;
    height: 100%;
    background-color: #1c1c1c;
    position: relative;
    transition: all cubic-bezier(0, 0.81, 0, 1.16) 0.3s;
    .course_desc {
      display: flex;
      width: calc(100% - 40px);
      height: 60px;
      padding: 20px;
      background-color: #212121;
      .course_text {
        width: calc(100% - 115px);
        .course_title {
          width: 100%;
          color: #ffffff;
          font-size: 14px;
          margin-bottom: 30px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .course_teacher {
          color: #999999;
          font-size: 12px;
        }
      }
      .course_img {
        width: 100px;
        height: 60px;
        margin-left: 15px;
        background-color: #999999;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .mooc_tabs {
      display: flex;
      width: 100%;
      height: 48px;
      background-color: #262626;
      .tabs_item {
        width: calc(100% / 3);
        height: 48px;
        line-height: 48px;
        text-align: center;
        color: #999999;
        cursor: pointer;
      }
      .active {
        color: #ff8a00;
      }
    }
    .tabs_catalogue {
      width: calc(100% - 60px);
      height: calc(100% - 148px - 60px);
      padding: 30px;
      color: #fff;
      overflow-y: auto;
      .chapter_list {
        .chapter_item {
          font-size: 14px;
          color: #ffffff;
          margin-bottom: 20px;
          div:nth-child(1) {
            margin-right: 15px;
          }
          div:nth-child(2) {
            width: 18px;
            height: 18px;
            text-align: center;
            line-height: 18px;
            border-radius: 50%;
            border: 1px solid #ffffff;
            font-size: 12px;
            margin-right: 15px;
          }
          div:nth-child(3) {
            width: 70%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        .period_list {
          .period_item {
            margin-left: 5px;
            font-size: 12px;
            color: #fff;
            margin-bottom: 20px;
            div:nth-child(1) {
              margin-right: 15px;
            }
            div:nth-child(2) {
              width: 16px;
              height: 16px;
              text-align: center;
              line-height: 16px;
              border-radius: 50%;
              border: 1px solid #ffffff;
              font-size: 12px;
              margin-right: 15px;
            }
          }
          .topic_item {
            color: #999999;
            font-size: 12px;
            margin-bottom: 20px;
            cursor: pointer;
            div:nth-child(1) {
              width: 12px;
              height: 12px;
              border-radius: 50%;
              border: 1px solid #999999;
              margin-left: 46px;
              margin-right: 15px;
            }
            div:nth-child(2) {
              display: flex;
              justify-content: space-between;
              width: 70%;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              span {
                width: 110px;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }

            &:hover {
              color: #ff8a00;
              div:nth-child(1) {
                border: 1px solid #ff8a00;
                background: #ff8a00;
                position: relative;
                &::before {
                  content: "";
                  position: absolute;
                  width: 10px;
                  height: 10px;
                  background: black;
                  border-radius: 50%;
                  left: 50%;
                  margin-left: -5px;
                  top: 50%;
                  margin-top: -5px;
                }
              }
            }
          }
        }
      }
    }
  }
  .active-item {
    color: #ff8a00 !important;
    div:nth-child(1) {
      border: 1px solid #ff8a00 !important;
      background: #ff8a00 !important;
      &::before {
        content: "";
        display: none;
      }
    }
  }
  .active-num {
    border-color: #ff8a00 !important;
    color: #ff8a00;
  }
  .middle {
    width: calc(100% - 320px);
    height: 100%;
  }
  .big {
    width: 100%;
    height: 100%;
  }
  .show_right {
    display: block;
    transform: translateX(0);
  }
  .hide_right {
    transform: translateX(320px);
  }
}
.flex_space {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.flex {
  display: flex;
  align-items: center;
}
</style>