<template>
  <div class="mooc_exam">
    <!-- 未作答 -->
    <div class="no_answer" v-if="state === 'unAnswer'">
      <div class="img"></div>
      <div class="exam_name">这里现实的是测验的名称</div>
      <div class="exam_info">
        <span>总题数：10题</span>
        <span>总分：100分</span>
        <span>答题时长：不限</span>
      </div>
      <div class="begin">
        <el-button type="primary" @click="state = 'answering'"
          >开始答题</el-button
        >
      </div>
    </div>
    <!-- 作答中 -->
    <div class="answering" v-else-if="state === 'answering'">
      <!-- 单选题 -->
      <div class="choice_question" v-if="curTopic.type === 'choose'">
        <div class="topic">
          <div>{{ curTopic.topic }}</div>
          <div>倒计时：29:58</div>
        </div>
        <div class="options">
          <el-radio-group
            class="my_options"
            v-model="radio"
            v-for="(item, index) in curTopic.options"
            :key="index"
          >
            <el-radio :label="String.fromCharCode(65 + index)"
              >{{ String.fromCharCode(65 + index) }}.{{ item }}</el-radio
            >
          </el-radio-group>
        </div>
      </div>
      <!-- 判断题 -->
      <div class="judge_question" v-if="curTopic.type === 'judge'">
        <div class="topic">
          <div>{{ curTopic.topic }}</div>
          <div>倒计时：29:58</div>
        </div>
        <div class="judge_options">
          <div
            :class="judge_answer === 0 ? 'active' : ''"
            @click="judge_answer = 0"
          >
            正确
          </div>
          <div
            :class="judge_answer === 1 ? 'active' : ''"
            @click="judge_answer = 1"
          >
            错误
          </div>
        </div>
      </div>
      <!-- 问答题 -->
      <div class="essay_question" v-if="curTopic.type === 'essay'">
        <div class="topic">
          <div>{{ curTopic.topic }}</div>
          <div>倒计时：29:58</div>
        </div>
        <div class="essay_answer">
          <div class="essay_answer_key">作答:</div>
          <div class="essay_answer_content">
            <MyEditor
              ref="Editor"
              :value="textarea"
              @onEditor="onEditor"
            ></MyEditor>
          </div>
        </div>
      </div>
      <div class="answering_footer">
        <el-button
          :type="curTopic.no > 1 ? 'primary' : 'info'"
          :disabled="curTopic.no > 1 ? false : true"
          @click="curQuestionIndex--"
          >上一题</el-button
        >
        <el-popover
          :visible-arrow="false"
          placement="top"
          width="300"
          trigger="click"
          popper-class="answer_card"
        >
          <template #reference>
            <el-button type="primary"
              >{{ curTopic.no }}/{{ questionsList.length }}</el-button
            >
          </template>
          <div class="answer_card_content">
            <div class="answer_card_content_header">
              <div>答题卡</div>
              <div>总分100</div>
            </div>
            <div class="answer_card_content_list">
              <div
                class="answer_card_topic_item"
                :class="item === curTopic.no ? 'active' : ''"
                v-for="(item, index) in questionsList.length"
                :key="index"
              >
                {{ item }}
              </div>
            </div>
          </div>
        </el-popover>
        <el-button
          :type="curTopic.no < questionsList.length ? 'primary' : 'info'"
          :disabled="curTopic.no < questionsList.length ? false : true"
          @click="curQuestionIndex++"
          >下一题</el-button
        >
      </div>
    </div>
    <!-- 作答结束，解析 -->
    <div class="answered" v-else-if="state === 'answered'">
      <div class="topic">{{ curExplain.topic }}</div>
      <div class="answered_content">
        <div class="my_answer">
          <div class="my_answer_key">作答：</div>
          <div class="my_answer_content">
            <div class="content_text">
              {{ curExplain.answer }}
            </div>
            <div
              class="content_img"
              v-if="curExplain.srcList && curExplain.srcList.length"
            >
              <el-image
                style="width: 240px; height: 135px"
                :src="item"
                :preview-src-list="curExplain.srcList"
                v-for="(item, index) in curExplain.srcList"
                :key="index"
              >
              </el-image>
            </div>
          </div>
        </div>
        <div class="my_score">
          <div>得分：{{ curExplain.score }}分</div>
          <div>平均分：{{ curExplain.averageScore }}分</div>
        </div>
        <div class="knowledge">
          <div class="knowledge_tag">知识点</div>
          <div class="knowledge_item">
            {{ curExplain.knowledge }}
          </div>
        </div>
        <div class="analies">
          <div class="analies_tag">解析</div>
          <div class="analies_content">
            {{ curExplain.analysis }}
          </div>
        </div>
      </div>
      <div class="answered_footer">
        <el-button
          :type="curExplain.no > 1 ? 'primary' : 'info'"
          :disabled="curExplain.no > 1 ? false : true"
          @click="curExplainIndex--"
          >上一题</el-button
        >
        <el-popover
          :visible-arrow="false"
          placement="top"
          width="300"
          trigger="click"
          popper-class="answer_card"
        >
          <template #reference>
            <el-button type="primary"
              >{{ curExplain.no }}/{{ explainList.length }}</el-button
            >
          </template>
          <div class="answer_card_content">
            <div class="answer_card_content_header">
              <div>答题卡</div>
              <div>总分100 得分98</div>
            </div>
            <div class="answer_card_content_list">
              <div
                class="answer_card_topic_item"
                :class="item === curExplain.no ? 'active' : ''"
                v-for="(item, index) in explainList.length"
                :key="index"
              >
                {{ item }}
              </div>
            </div>
          </div>
        </el-popover>
        <el-button
          :type="curExplain.no < explainList.length ? 'primary' : 'info'"
          :disabled="curExplain.no < explainList.length ? false : true"
          @click="curExplainIndex++"
          >下一题</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import MyEditor from "@/components/MyEditor/index.vue";

export default {
  components: {
    MyEditor,
  },
  data() {
    return {
      state: "unAnswer",
      // 答题中参数
      questionsList: [
        {
          type: "choose",
          no: 1,
          topic: "Q1.请问睿课互动课堂是一款什么样的产品？（单选题，10分）",
          options: ["体育产品", "教育产品", "音乐产品", "社交产品"],
        },
        {
          type: "judge",
          no: 2,
          topic: "Q2.请问睿课互动可以一次备课，多次上课？（判断题，10分）",
        },
        {
          type: "essay",
          no: 3,
          topic: "Q3.请问睿课堂是一款什么样的软件？（问答题，20分）",
        },
      ],
      curQuestionIndex: 0,
      radio: "",
      judge_answer: "",
      textarea: "",
      //已作答参数
      explainList: [
        {
          no: 1,
          topic: "Q1.请问睿课互动课堂是一款什么样的产品？（单选题，10分）",
          answer:
            "图文混合的作答内容 ，点击图片可放大查看。 的样子，欣欣然张开了眼。山朗润起来了，水涨起来了，太阳的脸红起来了。小草偷偷地从土地里钻出来，嫩嫩的， 绿绿的。园子里，田野里，瞧去，一大片一大片满是的。坐着，躺着，打两个滚，踢几脚球，赛几趟跑，捉几回迷 藏。风轻悄悄的，草软绵绵的。",
          srcList: [
            "https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fup.enterdesk.com%2Fedpic_source%2Fbd%2Fd2%2Fc3%2Fbdd2c32a5604fa4c81fe77ed53e596e4.jpg&refer=http%3A%2F%2Fup.enterdesk.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1650004553&t=1790e1c069e6789e6f80ddc6d7fbe533",
            "https://img2.baidu.com/it/u=3118654081,2421462464&fm=253&fmt=auto&app=138&f=JPEG?w=889&h=500",
            "https://img0.baidu.com/it/u=1376456886,3391905883&fm=253&fmt=auto&app=120&f=JPEG?w=1422&h=800",
          ],
          score: 10,
          averageScore: 8,
          knowledge: "知识点标签1、知识点标签2、知识点标签3",
          analysis:
            "这里是解析的内容，如果一行不够就换行显示。这里是解析的内容，如果一行不够就换行显示。这里是解析的内容，如果一行不够就换行显示。",
        },
        {
          no: 2,
          topic: "Q2.请问睿课互动课堂是一款什么样的产品？（单选题，10分）",
          answer:
            "图文混合的作答内容 ，点击图片可放大查看。 的样子，欣欣然张开了眼。山朗润起来了，水涨起来了，太阳的脸红起来了。小草偷偷地从土地里钻出来，嫩嫩的， 绿绿的。园子里，田野里，瞧去，一大片一大片满是的。坐着，躺着，打两个滚，踢几脚球，赛几趟跑，捉几回迷 藏。风轻悄悄的，草软绵绵的。",
          score: 10,
          averageScore: 8,
          knowledge: "知识点标签1、知识点标签2、知识点标签3",
          analysis:
            "这里是解析的内容，如果一行不够就换行显示。这里是解析的内容，如果一行不够就换行显示。这里是解析的内容，如果一行不够就换行显示。",
        },
      ],
      curExplainIndex: 0,
    };
  },
  computed: {
    curTopic() {
      return this.questionsList[this.curQuestionIndex];
    },
    curExplain() {
      return this.explainList[this.curExplainIndex];
    },
  },
  methods: {
    onEditor(value) {
      this.textarea = value;
    },
  },
};
</script>

<style lang="scss" scoped>
.mooc_exam {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  color: #fff;
  .no_answer {
    text-align: center;
    .img {
      width: 100px;
      height: 140px;
      background-color: rgba($color: #000000, $alpha: 0.5);
      margin: 0 auto;
      margin-bottom: 70px;
    }
    .exam_name {
      font-size: 32px;
      margin-bottom: 60px;
    }
    .exam_info {
      color: #cccccc;
      font-size: 24px;
      margin-bottom: 60px;
      span {
        margin-right: 60px;
      }
      span:last-child {
        margin-right: 0px;
      }
    }
  }
  .answering {
    position: relative;
    width: 100%;
    height: 100%;
    .choice_question {
      box-sizing: content-box;
      width: calc(100% - 120px);
      height: calc(100% - 120px);
      padding: 60px;
      .topic {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 24px;
        margin-bottom: 35px;
        div:nth-child(1) {
          color: #ffffff;
        }
        div:nth-child(2) {
          color: #f27200;
        }
      }
    }
    .judge_question {
      box-sizing: content-box;
      width: calc(100% - 120px);
      height: calc(100% - 120px);
      padding: 60px;
      .topic {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 24px;
        margin-bottom: 35px;
        div:nth-child(1) {
          color: #ffffff;
        }
        div:nth-child(2) {
          color: #f27200;
        }
      }
      .judge_options {
        margin-left: 40px;
        div {
          width: 90px;
          height: 40px;
          line-height: 40px;
          text-align: center;
          border: 2px solid #888888;
          border-radius: 4px;
          color: #ffffff;
          font-size: 18px;
          margin-bottom: 40px;
          cursor: pointer;
        }
        .active {
          border-color: #f27200;
          color: #f27200;
        }
      }
    }
    .essay_question {
      box-sizing: content-box;
      width: calc(100% - 120px);
      height: calc(100% - 120px);
      padding: 60px;
      .topic {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 24px;
        margin-bottom: 35px;
        div:nth-child(1) {
          color: #ffffff;
        }
        div:nth-child(2) {
          color: #f27200;
        }
      }
      .essay_answer {
        display: flex;
        height: 300px;
        .essay_answer_key {
          width: 60px;
          margin-right: 20px;
          color: #fff;
          font-size: 24px;
        }
        .essay_answer_content {
          width: calc(100% - 80px);
          z-index: 10;
        }
      }
    }
    .answering_footer {
      position: absolute;
      bottom: 60px;
      left: 60px;
      .el-button {
        margin-right: 10px;
      }
    }
  }
  .answered {
    position: relative;
    width: calc(100% - 120px);
    height: calc(100% - 120px);
    padding: 60px;
    color: #ffffff;
    font-size: 24px;
    .topic {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 35px;
      height: 30px;
    }
    .answered_content {
      width: 100%;
      height: calc(100% - 130px);
      overflow-y: auto;
    }
    .my_answer {
      display: flex;
      margin-left: 40px;
      margin-bottom: 60px;
      .my_answer_key {
        width: 80px;
        margin-right: 20px;
        line-height: 40px;
      }
      .my_answer_content {
        width: calc(100% - 100px);
        .content_text {
          line-height: 40px;
        }
        .content_img {
          display: grid;
          grid-template-columns: repeat(4, 1fr);
          gap: 30px;
          margin-top: 20px;
        }
      }
    }
    .my_score {
      display: flex;
      margin-left: 40px;
      margin-bottom: 40px;
      div:nth-child(1) {
        margin-right: 10px;
      }
    }
    .knowledge {
      display: flex;
      align-items: center;
      margin-left: 40px;
      margin-bottom: 40px;
      .knowledge_tag {
        width: 60px;
        height: 24px;
        line-height: 24px;
        text-align: center;
        background-color: #ff8a00;
        border-radius: 4px;
        font-size: 14px;
        margin-right: 20px;
      }
      .knowledge_item {
        font-size: 24px;
      }
    }
    .analies {
      display: flex;
      margin-left: 40px;
      .analies_tag {
        width: 60px;
        height: 24px;
        line-height: 24px;
        text-align: center;
        background-color: #b655c2;
        border-radius: 4px;
        font-size: 14px;
        margin-right: 20px;
        margin-top: 10px;
      }
      .analies_content {
        width: calc(100% - 80px);
        font-size: 24px;
        line-height: 40px;
      }
    }
    .answered_footer {
      position: absolute;
      bottom: 60px;
      left: 60px;
      .el-button {
        margin-right: 10px;
      }
    }
  }
}
</style>

<style lang="scss">
.answer_card {
  width: 260px;
  height: 200px;
  padding: 20px;
  background: rgba($color: #484848, $alpha: 0.95) !important;
  border-radius: 4px;
  border: 0px !important;
  .answer_card_content {
    width: 100%;
    height: 100%;
    .answer_card_content_header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #fff;
      font-size: 18px;
      margin-bottom: 20px;
    }
    .answer_card_content_list {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      gap: 15px;
      .answer_card_topic_item {
        width: 40px;
        height: 40px;
        text-align: center;
        line-height: 40px;
        font-size: 24px;
        color: #fff;
        border-radius: 40px;
        background-color: #cccccc;
        cursor: pointer;
      }
      .active {
        background-color: #11c98a;
      }
    }
  }
  .el-popper__arrow {
    display: none;
  }
}
</style>