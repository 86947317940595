<template>
  <div class="mooc_live">
    <div class="live_main">
      <div class="wrap">
        <div class="live_name">{{ liveInfo.ref.title }}</div>
        <div
          class="live_time"
          :class="
            liveInfo.liveState === 0
              ? 'orange'
              : liveInfo.liveState === 1
              ? 'green'
              : 'gray'
          "
        >
          <span>{{ liveInfo.startDate }} {{ liveInfo.liveStateName }}</span>
        </div>
        <div class="middle">
          <div class="padding_desc">
            <div class="live_desc">
              {{ liveInfo.ref.intro }}
            </div>
          </div>
        </div>

        <div class="live_teacher">
          <div class="teacher_img">
            <d-avatar :size="48"></d-avatar>
          </div>
          <div class="teacher_infos">
            <div>讲师：{{ liveInfo.teamName }}</div>
            <div>资深数学教师</div>
          </div>
        </div>
        <div class="playback">
          <el-button v-if="liveInfo.liveState === 1" type="primary" size="mini">
            进入直播
          </el-button>
          <el-button
            v-if="liveInfo.liveState === 2 && liveInfo.ref.allowRecord"
            type="primary"
            size="mini"
          >
            查看回放
          </el-button>
        </div>
      </div>
    </div>
    <div v-if="liveInfo.liveState === 0" class="live_footer">
      <div class="count_down">
        距离直播开始还有：
        <label>{{ startTmInterval.days }}</label> 天
        <label>{{ startTmInterval.hours }}</label> 时
        <label>{{ startTmInterval.minutes }}</label> 分
        <label>{{ startTmInterval.seconds }}</label> 秒
      </div>
      <div class="remind">
        <el-button type="primary" round size="mini">
          <i class="el-icon el-icon-bell"></i>
          开启提醒
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import * as UtilsJs from "@/scripts/utils";
export default {
  name: "MoocLive",
  props: {
    info: Object,
  },
  data() {
    return {
      // 定时器
      timer: null,
      liveState: 0,
      liveInfo: {
        ref: {
          title: "",
          intro: "",
        },
      },
      startTmInterval: {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      },
    };
  },
  mounted() {
    this.liveInfo = { ...this.info };
    this.handleTimeDealer();
    if (this.liveInfo.liveState === 0) {
      this.timer = setInterval(() => {
        this.handleRoll();
      }, 1000);
    }
  },
  methods: {
    // 时间处理
    handleTimeDealer() {
      this.liveInfo.startDate = UtilsJs.timeDealer(this.info.ref.gmtStart);
      if (new Date().getTime() < this.info.ref.gmtStart) {
        let interval = this.info.ref.gmtStart - new Date().getTime();
        // 日
        this.startTmInterval.days = Math.floor(interval / 1000 / 60 / 60 / 24);
        interval = interval - this.startTmInterval.days * 24 * 60 * 60 * 1000;
        // 时
        this.startTmInterval.hours = Math.floor(interval / 60 / 60 / 1000);
        interval = interval - this.startTmInterval.hours * 60 * 60 * 1000;
        // 分
        this.startTmInterval.minutes = Math.floor(interval / 60 / 1000);
        interval = interval - this.startTmInterval.minutes * 60 * 1000;
        // 秒
        this.startTmInterval.seconds = Math.floor(interval / 1000);
        this.liveInfo.liveState = 0;
        this.liveInfo.liveStateName = "开始直播";
      }
      if (
        new Date().getTime() > this.info.ref.gmtStart &&
        new Date().getTime() < this.info.ref.gmtStop
      ) {
        this.liveInfo.liveState = 1;
        this.liveInfo.liveStateName = "直播中...";
      }
      if (
        new Date().getTime() > this.info.ref.gmtStart &&
        new Date().getTime() > this.info.ref.gmtStop
      ) {
        this.liveInfo.liveState = 2;
        this.liveInfo.liveStateName = "已结束";
      }
    },
    // 定时器的方法
    handleRoll() {
      if (this.startTmInterval.seconds > 0) {
        this.startTmInterval.seconds--;
      } else {
        this.startTmInterval.seconds = 59;
        if (this.startTmInterval.minutes > 0) {
          this.startTmInterval.minutes--;
        } else {
          this.startTmInterval.minutes = 59;
          if (this.startTmInterval.hours > 0) {
            this.startTmInterval.hours--;
          } else {
            this.startTmInterval.hours = 23;
            if (this.startTmInterval.days > 0) {
              this.startTmInterval.days--;
            } else {
              this.startTmInterval.days = 0;
            }
          }
        }
      }
    },
  },
  beforeUnmount() {
    clearInterval(this.timer);
  },
};
</script>

<style lang="scss" scoped>
.mooc_live {
  width: 100%;
  height: 100%;
  color: #fff;
  .live_main {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: calc(100% - 60px);
    .wrap {
      width: 100%;
      .live_name {
        font-size: 40px;
        margin-bottom: 20px;
        text-align: center;
      }
      .live_time {
        width: 260px;
        height: 32px;
        line-height: 32px;
        text-align: center;
        font-size: 18px;
        border-radius: 16px;
        margin: 25px auto;
        padding: 0 10px;
      }
      .orange {
        background-color: #f27200;
      }
      .green {
        background-color: #4bb31d;
      }
      .gray {
        background-color: #878787;
      }
      .middle {
        text-align: center;
        margin: 40px auto;
        .padding_desc {
          width: calc(100% - 100px - 200px);
          height: 100px;
          padding: 20px 50px;
          display: inline-block;
          border: 2px solid rgba($color: #fff, $alpha: 0.3);
          text-align: left;
          text-indent: 22px;
          .live_desc {
            line-height: 24px;
            color: rgba($color: #fff, $alpha: 0.5);
            display: -webkit-box;
            overflow: hidden;
            -webkit-line-clamp: 4;
            text-overflow: ellipsis;
            -webkit-box-orient: vertical;
          }
        }
      }

      .live_teacher {
        display: flex;
        justify-content: center;
        margin-bottom: 50px;
        .teacher_img {
          margin-right: 15px;
        }
        .teacher_infos {
          div:nth-child(1) {
            font-size: 18px;
            margin-bottom: 15px;
          }
          div:nth-child(2) {
            font-size: 14px;
            color: rgba($color: #fff, $alpha: 0.5);
          }
        }
      }
      .playback {
        display: flex;
        justify-content: center;
      }
    }
  }
  .live_footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: calc(100% - 40px);
    height: 60px;
    padding: 0 20px;
    background-color: rgba($color: #000000, $alpha: 0.5);
    .count_down {
      label {
        color: #f27200;
        font-size: 24px;
      }
    }
  }
}
</style>