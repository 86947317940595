
import { onMounted, reactive, toRefs, defineComponent, ref } from "vue";
import { post } from "@/scripts/http";

import E from "wangeditor";

interface DataProps {
  editor: any;
  editorContent: string;
  getContent: (ref?: any) => void;
}

export default defineComponent({
  name: "Editor",
  components: {},
  props: {
    options: Object,
    value: String,
  },
  setup(props, { emit }) {
    const editorElemMenu = ref();
    const editorElemBody = ref();
    const data: DataProps = reactive({
      editorContent: "",
      editor: {},
      getContent: () => {
        console.log("111", props);
        data.editor.txt.html(props.value);
      },
    });
    onMounted(() => {
      let _this = this;
      const elemMenu = editorElemMenu.value;
      const elemBody = editorElemBody.value;
      data.editor = new E(elemMenu, elemBody);
      // 使用 onchange 函数监听内容的变化，并实时更新到 state 中
      data.editor.config.onchange = (html: any) => {
        // console.log(data.editor.txt.html());
        data.editorContent = data.editor.txt.html();
        // 向外部返回一个处理过的值
        emit("onEditor", data.editor.txt.html());
        emit("update:value", data.editor.txt.html());
      };
      data.editor.config.customUploadImg = async function (
        files: any,
        insert: any
      ) {
        // files 是 input 中选中的文件列表insert 是获取图片 url 后，插入到编辑器的方法
        // let file;
        // if (files && files.length) {
        //   file = files[0];
        // } else {
        //   return;
        // }
        // 图片上传
        const formData = new FormData();
        formData.append("picFile", files[0]);
        // formData.append('Banners',{id:editorinfo.id,naviChildId:editorinfo.naviChildId})

        const res = await post(`/zone/auth/upload/res/pic`, formData);
        if (res && res.data.fileUrl) {
          insert(res.data.fileUrl);
        }
      };
      data.editor.config.menus = [
        "head", // 标题
        "bold", // 粗体
        "fontSize", // 字号
        "fontName", // 字体
        "italic", // 斜体
        "underline", // 下划线
        "strikeThrough", // 删除线
        "foreColor", // 文字颜色
        "backColor", // 背景颜色
        "link", // 插入链接
        "list", // 列表
        "justify", // 对齐方式
        "quote", // 引用
        "emoticon", // 表情
        "image", // 插入图片
        // "table", // 表格
        // "video", // 插入视频
        // "code", // 插入代码
        "undo", // 撤销
        "redo", // 重复
      ];
      data.editor.config.uploadImgShowBase64 = true;
      data.editor.config.height = "500px";
      data.editor.create();
      // data.getContent()
    });
    const refData = toRefs(data);
    return {
      ...refData,
      editorElemMenu,
      editorElemBody,
    };
  },
});
