<template>
  <div class="mooc_doc">这是文档类型</div>
</template>

<style lang="scss">
.mooc_doc {
  width: 100%;
  height: 100%;
  background-color: #fff;
}
</style>